<template>
    <div class="form">
    <form class="form-container" action="/submit.php" method="post" enctype="multipart/form-data">
        <img class="logo" src="./assets/reviewview-logo.jpg" />
        <transition name="vt-fade">
            <div v-show="slide == 1">
                <div class="field">
                    <input type="text" name="lastname" id="lastname" placeholder=" " />
                    <label for="lastname">Lastname</label>
                </div>

                <div class="field">
                    <input type="text" name="firstname" id="firstname" placeholder=" " />
                    <label for="firstname">Firstname</label>
                </div>
            </div>
        </transition>
        <transition name="vt-fade">
            <div v-show="slide == 2">
                <div class="field">
                    <input type="text" name="telno" id="telno" placeholder=" " />
                    <label for="telno">Tel No.</label>
                </div>

                <div class="field">
                    <input type="email" name="email" id="email" placeholder="jane.appleseed@example.com" />
                    <label for="email">Email</label>
                </div>
            </div>
        </transition>
        <transition name="vt-fade">
            <div v-show="slide == 3">
                <div class="field">
                    <label for="birthdate">Birthdate</label>

                    <input
                        type="date" id="start" name="birthdate"
                        value="2018-07-22"
                        min="1950-01-01" max="2021-12-31" />
                </div>
            </div>
        </transition>
        <transition name="vt-fade">
            <div v-show="slide == 4">
                <div class="field">
                    <input type="text" name="address" id="address" placeholder=" " />
                    <label for="address">Address</label>
                </div>

                <div class="field">
                    <input type="text" name="facility" id="facility" placeholder=" " />
                    <label for="facility">School / Facility/ Company</label>
                </div>
            </div>
        </transition>

        <transition name="vt-fade">
            <div v-show="slide == 5">
                <div class="field">
                    <label for="insurancefront">Select image:</label>
                    <input type="file" id="insurancefront" name="insurancefront" accept="image/*">
                </div>

                <div class="field">
                    <label for="insuranceback">Select image:</label>
                    <input type="file" id="insuranceback" name="insuranceback" accept="image/*">
                </div>

                <div class="field">
                    <label for="idfront">Select image:</label>
                    <input type="file" id="idfront" name="idfront" accept="image/*">
                </div>
            </div>
        </transition>

        <div class="form-nav">
            <button type="button" v-show="slide > 1" v-on:click="slide += 1"> Back </button>
            <button type="button" v-on:click="slide += 1"> Next </button>
            <input type="submit" v-on:click="slide == 5" value="submit">
        </div>
    </form>
    
    </div>
</template>

<script>
    export default {
        name: "App",
        data() {
            return {
                slide: 1
            }
        },
        components: {},
        mounted() {}
    };
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500&display=swap');

    .form-container {
        border: solid 1px gray;
        padding: 1rem;
        border-radius: .25rem;
    }

    .form-nav {
        display: flex;
    }

    body {
        font-family: 'Open Sans', sans-serif;
        margin: 0;
    }

    form {
        margin: 2em 0;
    }

    .logo {
        width: 70%;
        max-width: 300px;
        margin: 0 auto;
        display: block;
    }

    .form {
        margin: 0 auto;
        width: 80%;
    }
    /**
    * Make the field a flex-container, reverse the order so label is on top.
    */

    .field {
        display: flex;
        flex-flow: column-reverse;
        margin-bottom: 1em;
    }
    /**
    * Add a transition to the label and input.
    * I'm not even sure that touch-action: manipulation works on
    * inputs, but hey, it's new and cool and could remove the 
    * pesky delay.
    */
    label,
    input {
        transition: all 0.2s;
        touch-action: manipulation;
    }

    input {
        font-size: 1.5em;
        border: 0;
        border-bottom: 1px solid #ccc;
        font-family: inherit;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 0;
        cursor: text;
    }

    input:focus {
        outline: 0;
        border-bottom: 1px solid #666;
    }

    label {
        text-transform: uppercase;
        letter-spacing: 0.05em;
        color: #626262;
    }
    /**
    * Translate down and scale the label up to cover the placeholder,
    * when following an input (with placeholder-shown support).
    * Also make sure the label is only on one row, at max 2/3rds of the
    * field—to make sure it scales properly and doesn't wrap.
    */
    input:placeholder-shown + label {
        cursor: text;
        max-width: 66.66%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 2.125rem) scale(1.5);
    }
    /**
    * By default, the placeholder should be transparent. Also, it should 
    * inherit the transition.
    */
    ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
    }
    /**
    * Show the placeholder when the input is focused.
    */
    input:focus::-webkit-input-placeholder {
        opacity: 1;
    }
    /**
    * When the element is focused, remove the label transform.
    * Also, do this when the placeholder is _not_ shown, i.e. when 
    * there's something in the input at all.
    */
    input:not(:placeholder-shown) + label,
    input:focus + label {
        transform: translate(0, 0) scale(1);
        cursor: pointer;
    }
</style>
